/* You can add global styles to this file, and also import other style files */
@import "@angular/material/prebuilt-themes/deeppurple-amber.css";
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;500;700;800&display=swap");
@import "~bootstrap/dist/css/bootstrap.min.css";
.mat-datepicker-toggle .mat-icon-button {
  background-color: #16c7fe;
}

.mat-calendar {
  background-color: white;
}

/* body {
  margin: 2rem;
  font-family: Roboto, "Helvetica Neue", sans-serif;
} */

.mat-form-field-infix {
  margin-right: 0.5rem;
  margin-bottom: 0.3rem;
  font-size: 0.9rem;
}
